import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { kebabCase } from "lodash";

const tabHandler = (toActivate) => () => {
  var tabToggle = document.getElementsByClassName("location-tab");
  for (var i = 0; i < tabToggle.length; i++) {
    if (
      (tabToggle[i].classList.contains("tab-" + toActivate) &&
        !tabToggle[i].classList.contains("is-active")) ||
      (tabToggle[i].classList.contains("is-active") &&
        !tabToggle[i].classList.contains("tab-" + toActivate))
    ) {
      tabToggle[i].classList.toggle("is-active");
    }
  }
  var facilityToggle = document.getElementsByClassName("facility");
  for (var j = 0; j < facilityToggle.length; j++) {
    if (facilityToggle[j].classList.contains(toActivate)) {
      facilityToggle[j].style.display = "block";
    } else {
      facilityToggle[j].style.display = "none";
    }
  }
};

// eslint-disable-next-line
export const LocationsPageTemplate = ({
  title,
  helmet,
  content,
  contentComponent,
  facilities,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content content-border">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <hr />
            <PostContent content={content} />
            {facilities && facilities.length ? (
              <div style={{ marginTop: `2rem` }}>
                <div className="tabs is-large is-centered">
                  <ul>
                    <li className="location-tab tab-bay-of-plenty is-active">
                      <a onClick={tabHandler("bay-of-plenty")}>
                        <strong>Bay of Plenty</strong>
                      </a>
                    </li>
                    <li className="location-tab tab-waikato">
                      <a onClick={tabHandler("waikato")}>
                        <strong>Waikato</strong>
                      </a>
                    </li>
                    <li className="location-tab tab-lakes">
                      <a onClick={tabHandler("lakes")}>
                        <strong>Lakes</strong>
                      </a>
                    </li>
                  </ul>
                </div>
                <p className="facility bay-of-plenty has-text-centered">
                  <strong>
                    Bay of Plenty{" "}
                    <a className="has-text-primary" href="tel:+6475787073">
                      Phone (07) 578-7073
                    </a>
                  </strong>
                </p>
                <p className="facility waikato has-text-centered">
                  <strong>
                    Waikato{" "}
                    <a className="has-text-primary" href="tel:+6478580799">
                      Phone (07) 858-0799
                    </a>
                  </strong>
                </p>
                <p className="facility lakes has-text-centered">
                  <strong>
                    Lakes{" "}
                    <a className="has-text-primary" href="tel:+6473497907">
                      Phone (07) 349-7907
                    </a>
                  </strong>
                </p>
                <div className="columns is-multiline">
                  {facilities.map((facility) => (
                    <div
                      key={facility.name + `facility`}
                      className={`has-text-centered column is-6 facility ${kebabCase(
                        facility.region
                      )}`}
                    >
                      <div className="simple-border">
                        <h4>{facility.name}</h4>
                        {facility.status === "open" ? (
                          <>
                            <p className="preserve-whitespace">
                              <a
                                className="has-text-primary"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://maps.google.com/?q=Pathlab+${facility.address}`}
                              >
                                <strong>{facility.address}</strong>
                              </a>
                            </p>
                            <p className="preserve-whitespace">
                              {facility.hours}
                            </p>
                            <p className="preserve-whitespace">
                              <em>{facility.weekends}</em>
                            </p>
                            <p className="preserve-whitespace">
                              <strong>{facility.lunch}</strong>
                            </p>
                          </>
                        ) : (
                          <>
                            <p className="preserve-whitespace closed">CLOSED</p>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

LocationsPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  facilities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
      phone: PropTypes.string,
      hours: PropTypes.string,
      weekends: PropTypes.string,
      lunch: PropTypes.string,
      region: PropTypes.string,
      status: PropTypes.string,
    })
  ),
};

const Locations = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <LocationsPageTemplate
        helmet={
          <Helmet titleTemplate="%s | Pathlab">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        content={post.html}
        contentComponent={HTMLContent}
        facilities={post.frontmatter.facilities}
      />
    </Layout>
  );
};

Locations.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Locations;

export const pageQuery = graphql`
  query LocationsByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        facilities {
          name
          address
          phone
          hours
          weekends
          lunch
          region
          status
        }
      }
    }
  }
`;
